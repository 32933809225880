/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqi from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import Prestamype from "@components/sharedComponents/customersLogos/logos/Prestamype.svg"
import Chiolecca from "@components/sharedComponents/customersLogos/logos/Chiolecca.svg"
import LaMatier from "@components/sharedComponents/customersLogos/logos/LaMatier.svg"
import CasaRossello from "@components/sharedComponents/customersLogos/logos/CasaRossello.svg"

// logos testimonios
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"
import CulquiTes from "@components/sharedComponents/testimonies/images/logos/culqi.svg"
import CepebanTes from "@components/sharedComponents/testimonies/images/logos/cepeban.svg"
import CostagasTes from "@components/sharedComponents/testimonies/images/logos/costagas.svg"

//foto testimonio desktop
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import culqiDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/culqi.png"
import cepebanDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cepeban.png"
import costagasDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/costagas.png"

//foto testimonio desktop
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import culqiMobile from "@components/sharedComponents/testimonies/images/fotosMobile/culqi.png"
import cepebanMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cepeban.png"
import costagasMobile from "@components/sharedComponents/testimonies/images/fotosMobile/costagas.png"

import { TitleUnderlineMarketingOne, TitleUnderlineMarketingTwo } from "@components/pageCasosdeUso/components/intro/titleUnderlineMarketing"
import imageStartMarketing from "@components/pageCasosdeUso/pageAlternativaMarketing/image/start.png"

//iconos de funcion
import card1 from "@components/pageScore/pageContactCenterOmnicanal/image/card1.png"
import card2 from "@components/pageScore/pageContactCenterOmnicanal/image/card2.png"
import card3 from "@components/pageScore/pageContactCenterOmnicanal/image/card3.png"
import card4 from "@components/pageScore/pageContactCenterOmnicanal/image/card4.png"
import card5 from "@components/pageScore/pageContactCenterOmnicanal/image/card5.png"

//iconos usar ticker
import telefono from "@components/pageCasosdeUso/pageAlternativaMarketing/image/telefono.svg"
import pilar from "@components/pageCasosdeUso/pageAlternativaMarketing/image/pilar.svg"
import etiqueta from "@components/pageCasosdeUso/pageAlternativaMarketing/image/etiqueta.svg"
import seguimiento from "@components/pageCasosdeUso/pageAlternativaMarketing/image/seguimiento.svg"
import robot from "@components/pageCasosdeUso/pageAlternativaMarketing/image/robot.svg"
import grafico from "@components/pageCasosdeUso/pageAlternativaMarketing/image/grafico.svg"

import imgQuestion from "@components/pageCasosdeUso/pageAlternativaMarketing/image/imgQuestion.png"

let data = {
  start: {
    title: "Convierte más con nuestra plataforma omnicanal para telemarketing",
    textbody: "Beex unifica todos tus puntos de contacto con las herramientas de tu empresa en un solo lugar. También cuenta con marcadores, visores en tiempo real y mucho más. ¡Todo lo que necesitas para potenciar tus campañas de telemarketing!",
    image: imageStartMarketing,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqi,
    Auna,
    Astara,
    Alfin,
    Unifef,
    Proempresa,
    Synlab,
    Prestamype,
    Chiolecca,
    LaMatier,
    CasaRossello
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqi, Auna, Astara, Alfin, Unifef],
    arrayTwo: [Proempresa, Synlab, Prestamype, Chiolecca, LaMatier, CasaRossello],
  },

  contacts: {
    title: "Aumenta tus ventas y la satisfacción al cliente",
    textbody: "Cuéntanos sobre los procesos de marketing y ventas de tu empresa y nuestro equipo te brindará un feedback detallado para potenciar tus operaciones.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: CasaRosselloTes,
      title:
        "Con Beex, hemos aumentado en +75% nuestros leads desde canal digital",
      textbody:
        "Casa Rosselló, compañía con más de 150 años de historia en el sector acabados y construcción, prospecta de forma más óptima con WhatsApp API y livechat.",
      name: "Marlen Vega",
      post: "Jefa de Marketing de Casa Rosselló",
      link: "/casos-de-exito/casa-rossello/",
      imgDesktop: casaRosselloDesktop,
      imgMobile: casaRosselloMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
    {
      image: AstaraTes,
      title:
        "Con Beex, triplicamos nuestras ventas y reducido el esfuerzo de remarketing en 93%",
      textbody:
        "Astara, concesionaria de automóviles con presencia en Latam, ha automatizado sus procesos de marketing y ventas para canal orgánico y de paga con WhatsApp API.",
      name: "Jonny Castillo",
      post: "Jefe Comercial de Astara",
      link: "/casos-de-exito/astara/",
      imgDesktop: astaraDesktop,
      imgMobile: astaraMobile,
      metrics: [
        {
          metric: "x3",
          descripcion: "aumentó sus ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "93%",
          descripcion: "redujo su esfuerzo para remarketing",
          color: "#5831D8",
        },
        {
          metric: "80%",
          descripcion: "mejoró contactabilidad con leads",
          color: "#FFB800",
        },
      ],
    },
    {
      image: CulquiTes,
      title:
        "Con Beex, hemos logrado mejorar la contactabilidad con nuestros leads en +65%",
      textbody:
        "Culqi, la fintech que revoluciona la forma de hacer pagos digitales, usa Beex para automatizar sus procesos de telemarketing, por canal telefónico y WhatsApp.",
      name: "Paul Vera",
      post: "Jefe de Telemarketing de Culqi",
      link: "/casos-de-exito/culqi/",
      imgDesktop: culqiDesktop,
      imgMobile: culqiMobile,
      metrics: [
        {
          metric: "+65%",
          descripcion: "mejoró su contactabilidad con sus leads",
          color: "#FFB800",
        },
        {
          metric: "x8",
          descripcion: "aumentó sus ventas mensuales",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "redujo el tiempo de recorrido de bases",
          color: "#FFB800",
        },
      ],
    },
    {
      image: CepebanTes,
      title: "Con Beex y el API de WhatsApp mejoramos nuestra tasa de conversión de leads en +27%",
      textbody:
        "CEPEBAN, instituto especializado en banca y finanzas, ha aumentado a +1.5k la cantidad de leads mejor prospectados con chatbots por WhatsApp Business API.",
      name: "Alan De La Cruz",
      post: "Jefe de Sistemas de CEPEBAN",
      link: "/casos-de-exito/cepeban/",
      imgDesktop: cepebanDesktop,
      imgMobile: cepebanMobile,
      metrics: [
        {
          metric: "+27%",
          descripcion: "mejoró su tasa de conversión con sus leads",
          color: "#FFB800",
        },
        {
          metric: "+1.5k",
          descripcion: "leads prospectados al mes por chatbots",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#FFB800",
        },
      ],
    },
    {
      image: CostagasTes,
      title: " Beex nos ha permitido reducir el tiempo de espera hasta en <1 minuto",
      textbody:
        "Costagas, compañía con más de 40 años en el sector energético, comercializa sus servicios de consumo masivo con Beex. Logrando mejorar sus ventas en 1.3x",
      name: "Natalia Pachas",
      post: "Jefe de Marketing en Costagas",
      link: "/casos-de-exito/costagas/",
      imgDesktop: costagasDesktop,
      imgMobile: costagasMobile,
      metrics: [
        {
          metric: "1.3x",
          descripcion: "aumento de ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "<1 minuto",
          descripcion: "tiempo de primera respuesta",
          color: "#5831D8",
        },
        {
          metric: "-40%",
          descripcion: "reducción de abandono",
          color: "#FFB800",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],


  question: {
    title: (
      <h2 className="title-question-pages-relative">
        Cómo potenciamos tus campañas de telemarketing?
      </h2>
    ),
    textbody: (
      <p>
        Optimizamos tu operación de telemarketing integrando los canales de
        comunicación con tu CRM. Con esto, podrás automatizar tus procesos más
        importantes de contacto con mayor personalización.
        <br /> <br />
        Además, podrás gestionar eficientemente las interacciones de tus
        contactos y mantener un registro actualizado, lo que facilitará la
        segmentación y el enfoque de tus estrategias.
      </p>
    ),
    image: imgQuestion,
  },

  useCases: {
    title: "¿Por qué elegir Beex para telemarketing?",
    description: "",
  },
  modules: [
    {
      title: "Enfoque omnicanal",
      img: card1,
      classtitle: "card-title-inbox",
      status: "Disponible",
      class: "texto-espacio",
      info:
        "Puedes unificar todos tus canales de comunicación: Telefonía, SMS, WhatsApp, Facebook, Instagram y mucho más. Conecta con tus clientes desde su canal preferido.",
    },
    {
      title: "Integración con tu base de datos",
      classtitle: "card-title-inbox",
      img: card2,
      info:
        "Beex está estructurado con APIs y webhooks que te permiten una rápida integración con las herramientas de información con las que estés trabajando, como un CRM o ERP.",
      status: "Disponible",
      class: "texto-espacio",
    },
    {
      title: "Marcadores inteligentes",
      classtitle: "card-title-inbox",
      img: card3,
      info:
        "Cuenta con marcadores progresivos y predictivos, lo que aumenta en más del 47% la contactabilidad de tus campañas. También vuelve más productivos a tus agentes.",
      status: "Disponible",
      class: "texto-espacio",
    },
    {
      title: "Visores en tiempo real",
      classtitle: "card-title-inbox",
      img: card4,
      info:
        "Conoce en tiempo real cómo se está gestionando tus campañas. También podrás conocer el estado de trabajo de tus agentes y escuchar sus llamadas o leer las conversaciones en vivo.",
      status: "Disponible",
      class: "texto-espacio",
    },
    {
      title: "API de WhatsApp sin costo alguno",
      classtitle: "card-title-inbox",
      img: card5,
      info:
        "Con esta herramienta podrás enviar mensajes masivos, recordatorios de reuniones, crear chatbots, tener una atención multiagente y mucho más.",
      status: "Disponible",
      class: "texto-espacio",
    },
  ],
  useScore: [
    {
      icon: telefono,
      title: "Lanzamiento de llamadas",
      text:
        "Mejora el contacto con tus leads y comunícate solo con números telefónicos que realmente contesten. Automatiza las llamadas de primer contacto sin intervención de un agente.",
    },
    {
      icon: pilar,
      title: "Actualización de datos",
      text:
        "Al tener integradas tus herramientas de información con tus canales, los datos de tus clientes se actualizan al instante luego de cada interacción. Todo desde un solo lugar.",
    },
    {
      icon: etiqueta,
      title: "Segmentación de prospectos",
      text:
        "Crea etiquetas a tus clientes y segméntalos para ofrecerles una comunicación mucho más personalizada y aumentar las probabilidades de conversión.",
    },
    {
      icon: seguimiento,
      title: "Seguimiento de leads",
      text:
        "Realiza el seguimiento de tus clientes potenciales no solo por llamada sino por mensajes personalizados por WhatsApp. Así, reduces el abandono.",
    },
    {
      icon: robot,
      title: "Automatización en reuniones",
      text:
        "Puedes añadir chatbots o IVR para que un cliente potencial pueda confirmar una reunión, cancelarla o reprogramarla. Todo eso sin el contacto de un agente.",
    },
    {
      icon: grafico,
      title: "Análisis de resultados",
      text:
        "Crea más de 40 reportes para verificar el éxito de tus campañas. También puedes monitorear en vivo tus indicadores para aplicar mejoras al instante.",
    },
  ],
}

export default data
